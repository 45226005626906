<template lang="pug">
a-layout-header(
  :class="className"
  :style="customStyle")
  slot
</template>
<script>
export default {
  name: "LayoutHeader",
  props: {
    customStyle: {
      type: Object,
      default: () => {}
    },
    className: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../less/main.less";
.header-main {
  background: var(--container-color) !important;
}
</style>
