<template lang="pug">
layout-header(
  :customStyle="{height: '75px', alignItems: 'center', padding: '0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}"
  className="header-main")
  div(:style="{minWidth:'215px', display:'grid', placeItems:'center', marginLeft:'2%'}")
    logo(mode="header")
  slot
</template>
<script>
//import molecules components
import Logo from "@/components/molecules/Logo.vue";

//Import organisms components
import LayoutHeader from "@/components/organisms/LayoutHeader.vue";

export default {
  name: "Header",
  components: {
    LayoutHeader,
    Logo
  }
};
</script>
